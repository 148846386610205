import React from "react";
import Identity from "./Identity";
import "../style/header.css"
import Brand from "./Brand";
import Fleche from "./fleche/Fleche";

export default function Header(props) {
    return (
        <header className="App-header">
            <div className={"flex-center-between header"}>
                <Brand/>
                <Identity/>
            </div>
            <a className={"tk-monarcha"} href={"mailto:contact@charles-tognol.fr?subject=Demande de contact&body=Bonjour,"}>contact@charles-tognol.fr</a>
            <Fleche onClick={props.onClick} />
        </header>
    )
}