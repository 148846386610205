import React from 'react';
import FormulaireContact from "./formulaire/FormulaireContact";
import Contact from "./contact/Contact";
import '../style/footer.css';

export default function Footer(){
    return (
        <footer className={"footer"}>
            <FormulaireContact/>
            <div style={{margin: 20+"px"}}>
                <h2 className={"name"}>Charles Tognol</h2>
            <Contact />
            </div>
        </footer>
    )
}