import React from 'react';
import {API} from "../../helper/define";
import "./formulaireContact.css";
import Popup from "../popup/Popup";

export default class FormulaireContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            msg: ""
        }
    }
    sendMsg = (e) => {
        e.preventDefault();
        let msg = "";
        let body = new FormData(e.target);
        fetch(API + "/contact/mail",
            {
                method: 'POST',
                body: body
            }
        ).then((reponse) => reponse.json())
            .then((data) => {
            console.log(data);
            if (data.ok) {
               msg = <p>Votre message à bien été envoyé ! <span role={"img"} aria-label={"Tout est OK"}>👌</span></p>;
                console.log("La requête a réussi");
                document.querySelector("#form").reset();
            } else {
               msg= <p>Une erreur est survenue ! <span role={"img"} aria-label={"Erreur"}>🙎‍‍</span><br/> Réessayez et si le problème persiste, <br/> contactez-moi via le courriel : <a href={"mailto:contact@charles-tognol.fr?subject=Demande de contact&body=Bonjour,"} itemProp={"email"} >contact@charles-tognol.fr</a></p>;
                console.log("la requête a échoué");
            }
            this.setState({msg: msg,
                popup: true
            });
        }).catch((error) => {
            console.log("Il y a eu une erreur pendant la requête");
            console.log(error);
            this.setState({msg: <p>Une erreur est survenue ! <span role={"img"} aria-label={"Erreur"}>🙎‍‍</span><br/> Réessayez et si le problème persiste. <br/> Contactez-moi via l'email : <a href={"mailto:contact@charles-tognol.fr?subject=Demande de contact&body=Bonjour,"} itemProp={"email"} >contact@charles-tognol.fr</a></p>,
                popup: true
            });
        });
    }

    handlePopup = () => {
        this.setState({popup: false});
    }
    render() {
        return (
            <div id="contain-form" onSubmit={this.sendMsg}>
                {this.state.popup && <Popup show={this.handlePopup} >{this.state.msg}</Popup>}
                <form id="form">
                    <h1 id="ff-proof" className="ribbon">Messagerie</h1>
                    <fieldset className="inputs" id="contain-select">
                        <input type={"text"} pattern={"^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?[.][a-zA-Z0-9]{2,}$"} name={"expediteur"} className={"glyph-input"} placeholder={"Email"} required/>
                        {/*<input type={"text"} pattern={"^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"} name={"expediteur"} className={"glyph-input"} placeholder={"Email"} required/>*/}
                    </fieldset>
                    <fieldset className="inputs">
                        {/*// <!--            <label for="expediteur">Sujet : </label>-->*/}
                        <input name="identite" id="identite" className="glyph-input" type="text"
                               placeholder={"nom - prenom"} required/>
                        {/*// <!--            <label for="destinataire">Message :</label>-->*/}
                        <textarea name="message" id="message" className="glyph-input"
                                  placeholder="Votre Message..." minLength={"10"} required></textarea>
                    </fieldset>
                    <fieldset id="actions">
                        <p className="option"><input className="option" type="submit" id="submit" value="envoyer"/></p>
                    </fieldset>
                </form>
            </div>
        )
    }
}