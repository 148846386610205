import React from "react";
import {API} from '../../helper/define';
import "./expererience.css";
import Fleche from "../fleche/Fleche";
import ItemExperience from "./ItemExperience";
import Cross from "../croix/Cross";

export default class Experiences extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            position: 0,
            isLoaded:false,
            items: []
        };
    }
    componentDidMount() { //La requête n'est pas appelé à chaque fois (Il y a un cache)
        fetch(API + "/formation")
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.hasOwnProperty("error")){
                        this.setState({
                            isLoaded: true,
                            error: true
                        });
                    }else{
                        this.setState({
                            isLoaded: true,
                            items: result,
                            error: false
                        });
                    }

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error: error
                    });
                }
            )
    }
    handlePosition = (move) => {
        let position = this.state.position;
        console.log("position");

        switch (move) {
            case "top":
                position--;
                break;
            default:
                position++;

        }

        this.setState({
            position: position,
        });

    }

    render() {
        const {error, isLoaded, close} = this.state;
        if (error) {
            return <div className={"msg-light"}>
                <h3>Une erreur est survenue !</h3>
                <p>Réessayer ou me contacter : <a
                    href={"mailto:contact@charles-tognol.fr?subject=Erreur de chargement&body=Bonjour, une erreur est survenue pendant le chargement des formations"}>contact@charles-tognol.fr</a>
                </p>
            </div>
        } else if (!isLoaded) {
            return <div className={"msg-light"}>Chargement ...</div> //todo faire un loader
        } else {
            return (
                /**
                 * @description Créé une page description d'expérience professionnel avec une animation (fusée de tintin)
                 * Pour que la fusée fonctionne il faut lors du clic de l'user ajouter la classe .decole-fusee à l'article
                 * Afin que l'animation de la fusée recommence à chaque clic il définir la props key
                 * @see experience.css Voir fichier CSS
                 * @author tognol charles
                 */

                <section className={"experiences flex"}>
                    {this.state.position !== 0 && <Fleche className={"fleche-top"} onClick={() => this.handlePosition("top")} />}
                    <Cross onClick={this.props.close} />
                    <ItemExperience key={this.state.position} item={this.state.items[this.state.position]}/>
                    {this.state.position !== this.state.items.length-1 && <Fleche onClick={this.handlePosition}/>}
                </section>
            )
        }
    }
}