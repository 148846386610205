/**
 * Class pour inserer une progressBar dans un container fourni
 * Une fois le chargement terminé la progressBar est supprimée
 * @since 2020
 * @author TOGNOL Charles
 */
export class ProgressBar {
    container = null;
    progressBarElmt = null;
    msg = null;
    isShowText = null;
    constructor(container, className = "", isShowText = false) {
        this.isShowText = isShowText;
        this.createElmnt(className);
        container.appendChild(this.container);
    }
    incremente(event){
        this.progressBarElmt.max = event.total;
        this.progressBarElmt.value = event.loaded;
        if(this.isShowText)
            this.msg.textContent = event.loaded + "/" + event.loaded;

        if(event.total === event.loaded){
            this.remove();
        }
    }
    error(){
        //todo afficher un message ou un glyphe
        this.remove();
    }
    remove(){
        this.container.remove();
        this.progressBarElmt = null;
        this.msg = null;
        this.container = null;
        this.isShowText = null;
    }
    createElmnt(className){
        this.container = document.createElement("div");
        this.container.classList.add("contain-progressBar");

        this.progressBarElmt = document.createElement("progress");
        this.progressBarElmt.classList.add(className, "progressBar");
        this.container.appendChild(this.progressBarElmt);
        if(this.isShowText){
            this.msg = document.createElement("span");
            this.msg.classList.add("msg");
            this.container.appendChild(this.msg);
        }
    }
}
