import React from 'react';
import './App.css';
import Header from './component/Header';
import Body from "./component/Body";
import Footer from "./component/Footer";
import './style/decoration.css';
import './style/position.css';
import './style/dimmension.css';
import './style/variable.css';
import './js/scrollEvent.js';

class App extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            body: false
        }
    }
    handleFleche = () => {
        this.setState({
            body: true
        });
        // let body = document.getElementsByClassName("body")[0];
        // if(body)
        //     window.scrollTo(0, document.getElementsByClassName("App-header")[0].offsetHeight);

    }
    close = () => {
        this.setState({body: false});
    }
    render() {
        return (
            <div className="App" id={"contain-background-star"}>
                <Header onClick={this.handleFleche} />
                { this.state.body && <Body close={this.close}/> }
                <Footer />
            </div>
        );
    }
}

export default App;