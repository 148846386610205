import React from 'react';
import './contact.css';
import {ReactComponent as Facebook} from '../../image/reseaux_sociaux/facebook_round.svg';
import {ReactComponent as Twiter} from '../../image/reseaux_sociaux/twiter_round.svg';
import {ReactComponent as Mail} from '../../image/reseaux_sociaux/mail_round.svg';
import {ReactComponent as Phone} from '../../image/reseaux_sociaux/phone_round.svg';
import {ReactComponent as Instagram} from '../../image/reseaux_sociaux/instagram_round.svg';
import {ReactComponent as Linkedin} from '../../image/reseaux_sociaux/linkedin_round.svg';


export default class Contact extends React.Component {
    handleMsg(msg) {

        document.querySelector("#link-social span").textContent = msg;
    }

    render() {
        return (
            <div onMouseLeave={()=> this.handleMsg("Pour me contacter ...")} className={"contain-contact"} itemScope itemType="https://schema.org/Person">
                <ul>
                    <li><a onMouseEnter={() => this.handleMsg("Facebook")}  href={"https://facebook.com/charles.tognol"} itemProp={"follows"} target={"_blank"} rel={"noopener noreferrer"} ><Facebook/></a>
                    </li>
                    <li><a onMouseEnter={() => this.handleMsg("Twitter")} href={"https://twitter.com/4charles2"} itemProp={"follows"} target={"_blank"} rel={"noopener noreferrer"}><Twiter/></a></li>
                    <li><a onMouseEnter={() => this.handleMsg("Courriel")} href={"mailto:contact@charles-tognol.fr?subject=Demande de contact&body=Bonjour,"}
                           itemProp={"email"}><Mail/></a></li>
                    <li id={"link-social"}><span>Pour me contacter ...</span></li>
                    <li><a onMouseEnter={() => this.handleMsg("06.23.04.90.30")} href={"tel:+33623049030"} itemProp={"telephone"}><Phone/></a>
                    </li>
                    <li><a onMouseEnter={() => this.handleMsg("Instagram")} href={"https://www.instagram.com/charlestognol/"} itemProp={"follows"} target={"_blank"} rel={"noopener noreferrer"}><Instagram/></a></li>
                    <li><a  onMouseEnter={() => this.handleMsg("Linkedin")} href={"https://fr.linkedin.com/in/tognol-charles-5786b8aa"} itemProp={"follows"} target={"_blank"} rel={"noopener noreferrer"}><Linkedin/></a></li>
                </ul>
            </div>
        )
    }
}