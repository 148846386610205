import React from "react";
import logo from "../image/logo_charles.svg";

export default function Identity (){
    return (
        <div className={"identity"} itemScope itemType={"https://schema.org/Person"}>
            <h1 className={"mr-b-none"} itemProp={"givenName"}>TOGNOL Charles</h1>
            <span className={"promote-text"} itemProp={"jobTitle"}>Développeur</span>
            <div>
                <div className={"tr-to-white s150 flex-center"} >
                    <img style={{position: "relative", right: -20+"px"}} width={100} src={logo} alt={"mon logo"} itemProp={"image"}/>
                </div>
                <ul className={"h150"}>
                    <li itemProp={"potentialAction"}>Web</li>
                    <li itemProp={"potentialAction"}>Mobile</li>
                    <li itemProp={"potentialAction"}>Automatisation</li>
                </ul>
            </div>
        </div>
    )
}