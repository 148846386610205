import React from 'react';
import '../style/body.css';
import Experiences from "./experience/Experiences";

export default class Body extends React.Component {

    componentDidMount() {
        window.scrollTo(0, document.querySelector(".body").offsetTop);
    }

    render() {
        return (
            <div className={"body"}>
                <Experiences close={this.props.close} />
            </div>
        )
    }
}