import React from "react";
import './buble.css';
import CSS from '../../image/language/CSS-logo.svg';
import PHP from '../../image/language/php-logo.svg';
import REACT from '../../image/language/react-logo.svg';
import SYMFONY from '../../image/language/symfony-logo.svg';
import PS from '../../image/language/photoshop-logo.svg';
import MYSQL from '../../image/language/MySql-logo.svg';
import JS from '../../image/language/javascript-logo.svg';
import JAVA from '../../image/language/java-logo.svg';
import GIT from '../../image/language/gitHub-logo.svg';
import HTML from '../../image/language/HTML-logo.svg';
import BASH from '../../image/language/bash-logo.svg';
import ANDROID from '../../image/language/android-logo.svg';
import AJAX from '../../image/language/ajax-logo.svg';
import AI from '../../image/language/AI-logo.svg';


const Language = [
    {name: CSS, strong: 1, class: "css"},
    {name: PHP, strong: 1, class: "php"},
    {name: REACT, strong: 2, class: "react"},
    {name: SYMFONY, strong: 3, class: "symfony"},
    {name: PS, strong: 3, class: "ps"},
    {name: MYSQL, strong: 2, class: "mysql"},
    {name: JS, strong: 1, class: "js"},
    {name: JAVA, strong: 1, class: "java"},
    {name: GIT, strong: 2, class: "git"},
    {name: HTML, strong: 2, class: "html"},
    {name: BASH, strong: 3, class: "bash"},
    {name: ANDROID, strong: 3, class: "android"},
    {name: AJAX, strong: 1, class: "ajax"},
    {name: AI, strong: 2, class: "ai"}
];

export default class Buble extends React.Component {
    buble() {
        let bubles = Array(Language.length).fill(null);
        for (let i = 0; i < bubles.length; i++) {
            bubles[i] = <img src={Language[i].name} className={"force"+Language[i].strong + " "+ Language[i].class} alt={"logo langage"} data-id={i} key={i}/>
            ;
        }
        return bubles;
    }

    render() {
        return (
            <div className={"buble"}>
                {this.buble()}
            </div>
        );
    }
}