checkOverFlowY();
window.addEventListener("rezise",  checkOverFlowY);

function checkOverFlowY(){
    if(window.getComputedStyle(document.querySelector("body")).overflowY === "hidden")
        window.addEventListener("wheel",
            handleElmtToScroll
        );
    else
        window.removeEventListener('wheel', handleElmtToScroll);
}


function handleElmtToScroll(event) {
    window.removeEventListener('wheel', handleElmtToScroll);
    if(window.innerWidth > 800)
        console.log("taille de la fenetre : " + window.innerWidth);

    let position = 0;
    let elmtToScroll = document.querySelectorAll(".App > *:not(canvas)");

    elmtToScroll.forEach((elmt, index) => {
        if (elmt.getBoundingClientRect().top === 0)
            position = index;
    });

    if (event.deltaY > 0 && position < elmtToScroll.length - 1) {
        scrollY(elmtToScroll[++position].offsetTop);
    } else if (event.deltaY < 0 && position > 0) {
        scrollY(elmtToScroll[--position].offsetTop);
    }

    setTimeout(() => {
        window.addEventListener('wheel', handleElmtToScroll)
    }, 800);
}

function scrollY(height) {
    window.scrollTo({
        top: height,
        left: 0,
        behavior: 'smooth'
    });

}