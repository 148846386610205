import React from 'react';
import {API} from '../../helper/define';

export default class ItemExperience extends React.Component {
 constructor(props){
     super(props);
     this.myRef = React.createRef();
 }
    createContent(data) {
        let paragraph = [];
        for (let i = 0; i < data.length; i++) {
            paragraph[i] = <p key={i}>{data[i].texte.toString()}</p>;
        }
        return paragraph;
    }
    scroll(){
        console.log("call to scroll function");
        
    }

    render() {
        const item = this.props.item;
        return (
            <article ref={this.myRef} onScroll={this.scroll()} className={"experience-contain decole-fusee"}>
                <span className={"date"}>{getDate(item.date_debut, item.date_fin)}</span>
                <div className={"flex"}>
                    <div className={"max-w130 contain-image-experience flex-center"}>
                        {item.name && <img src={API+"/public/image/" + item.name + "." + item.format}
                             alt={item.description_courte} width={"100%"}/>}
                    </div>
                    <h2 className={"experience-name"}>{item.diplome}{item.diplome && <br/>}{item.intitule}
                    </h2>
                </div>
                <div className={"experiencer-contenu-contain flex-center-between"}>
                    <div className={"experience-contenu mr-80"}>
                        <h3>{item.contenu.title.texte}</h3>
                        {this.createContent(item.contenu.paragraph)}
                    </div>
                    {item.contenu.aside.texte &&
                    <div className={"experience-aside promote-text"}>{item.contenu.aside.texte}</div>}
                </div>
            </article>
        )
    }
}

function getDate(begin, end) {
    begin = isDate(begin) ? new Date(begin).getFullYear() : begin;
    end = isDate(end) ? new Date(end).getFullYear() : end;

    if (isDate(begin) && isDate(end) && begin === end)
        return begin;
    else
        return begin + "-" + end;
}

function isDate(date) {
    let test = new Date(date);
    return test instanceof Date && !isNaN(test);
}