import * as THREE from "../../utils/three.js-master/build/three.module";
import {FBXLoader} from "../../utils/three.js-master/examples/jsm/loaders/FBXLoader";
import {ProgressBar} from "../../utils/ProgressBar/progressBar";
import "../../utils/ProgressBar/progressBar.css";
import {HOST} from "../../helper/define";
import {OrbitControls} from "../../utils/three.js-master/examples/jsm/controls/OrbitControls";

//todo remplacer window par self car pour le worker cela poser probleme
export class InitPersonnage{
    container = null;
    camera = null;
    scene = new THREE.Scene();
    renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
    controls = null; //Je n'ai pas besoin de OrbitControls ici
    light = new THREE.HemisphereLight(0xffffff, 0x444444);
    clock = new THREE.Clock();
    mixer = null;
    constructor(containerClassName) {
        this.container = document.querySelector(containerClassName);
        this.camera = new THREE.PerspectiveCamera(65, this.container.clientWidth / this.container.clientHeight, 1, 500);
        this.camera.position.set(0, 200, 100);
        // this.scene.background = new THREE.Color(0xa0a0a0); //Je veux un fond transparent
        this.scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000);
        this.scene.translateY(30);
        this.scene.translateX(-10);
        this.light.position.set(0, 200, 0);
        this.scene.add(this.light);

        let light = new THREE.DirectionalLight(0xffffff);
        light.position.set(0, 200, 100);
        light.castShadow = true;
        light.shadow.camera.top = 180;
        light.shadow.camera.bottom = -100;
        light.shadow.camera.left = -120;
        light.shadow.camera.right = 120;
        this.scene.add(light);


        // // // model
        var loader = new FBXLoader();
        let progressBar = new ProgressBar(this.container, "loadModel", true);
        loader.load(HOST + '/models/Silly Dancing 2.fbx', (object) => {
            this.mixer = new THREE.AnimationMixer(object);
            var action = this.mixer.clipAction(object.animations[0]);

            action.play();
            object.traverse(function (child) {
                if (child.isMesh) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });
            this.scene.add(object);
        }, (progress) => {
            progressBar.incremente(progress);
        }, (msgError) => {
            progressBar.error();
            console.log("error : " + msgError)
        });
//todo ajouter une image ou un loading pendant le chargement du models
        this.renderer.setPixelRatio(window.devicePixelRatio);
        // this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
        // this.renderer.shadowMap.enabled = true; //active les ombres portés ce n'est pas necessaire ici (dégage des ressources)
        this.container.appendChild(this.renderer.domElement);

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.target.set(0, 110, 10);
        this.controls.maxDistance = 300.0; //maximum du rétrécissement
        this.controls.minDistance = 250.0; //maximum du zoom
        this.controls.maxPolarAngle = Math.PI / 2;
        this.controls.minPolarAngle = Math.PI / 2;
        this.controls.update();
        window.addEventListener('resize', this.onWindowResize, false);
    }
    animate = () => {
        requestAnimationFrame(this.animate);

        var delta = this.clock.getDelta();

        if (this.mixer) this.mixer.update(delta);

        this.renderer.render(this.scene, this.camera);
    }
    onWindowResize = () => {

        this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);

    }
}