export function birthday(date){
    if(date instanceof Date) {
        let now = new Date();
        if (now.getMonth() >= date.getMonth() && now.getDate() >= date.getMonth())
            return now.getFullYear() - date.getFullYear();
        else
            return now.getFullYear() - date.getFullYear() - 1;
    }else
        console.error("Le parametre n'est pas une date");
}
