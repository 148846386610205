import React from 'react';
import './cross.css';

export default function Cross(props) {
    return (
        <span onClick={props.onClick}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="cross" width="26" height="26">
                        <line className="leftLine" fill="transparent" stroke="black" strokeWidth="4" y1="6" x1="6"
                              y2="20"
                              x2="20"/>
                        <line className="rightLine" fill="transparent" strokeWidth="4" x2="6" x1="20" y2="20" y1="6"
                              stroke="black"/>
                        <circle className="circle" r="10" fill="transparent" strokeWidth="2" cx="13" cy="13"
                                stroke="#a4a4a4"/>
                    </svg>
                </span>
    )
}