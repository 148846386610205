import React from "react";
import './fleche.css';
export default function Fleche(props){
    return(
        <div className={"fleche flex-center-column " + (props.className !== undefined ? props.className : "")}
            onClick={props.onClick }
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}