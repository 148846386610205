import React from 'react';
import './popup.css';
import Cross from "../croix/Cross";

export default function Popup(props) {
        return (
                <div className={"contain-popup flex-center"}>
                <div className={"popup"}>
                <Cross onClick={props.show} />
                <div>
                    {props.children}
                </div>
                </div>
            </div>
        )
}