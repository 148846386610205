import React from "react";
import Buble from "./buble/Buble";
import fluxlumineux from '../image/fluxLumineux.png';
import fluxlumineuxFront from '../image/fluxLumineuxFront.png';
import {ReactComponent as Bonhomme} from "../image/personnage/bonhomme.svg";
// import charles from '../image/personnage/charles.png';
import Personnage from './personnage/Personnage';
import './personnage/personnage.css';
import {WriteLetter} from "../utils/WriteLetter";
import {birthday} from "../utils/date/dates";

export default class Brand extends React.Component {

    componentDidMount() {
        {WriteLetter(".autoWrite","li",[ birthday(new Date(1986, 12, 22)) + " ans", "182 cm", "passionné"])}
    }

    render() {
        return (
            <div className={"brand s500 flex-center"}>

                <ul className={"autoWrite"}>
                </ul>
                {/*<div className={"personnage s250"}>*/}
                <img className={"backLum w250"} src={fluxlumineux} alt={"flux lumineux"}/>
                {/*<img className={"personnage h250"}  src={charles} alt={"moi"}/>*/}
                {/*<Bonhomme />*/}
                <Personnage />
                <img className={"frontLum w250"} src={fluxlumineuxFront} alt={"flux lumineux"}/>
                <Buble />
            </div>
        );
    }
}
