import React from 'react';
import './personnage.css';
import {InitPersonnage} from "./InitPersonnage";

// import {initFbxFile} from "../../utils/threeLibrary/initPersonnageLoader";

export default class Personnage extends React.Component {

    componentDidMount = () => {
        //todo faire un worker pour ne pas bloquer l'exécution des différents script
        //Problematique le worker n'importe pas les modules
        let model = new InitPersonnage("#scene-personnage");
        model.animate();
    }


    render() {
        return (
            <div id={"scene-personnage"}>
            </div>
        )
    }
}