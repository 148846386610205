export function WriteLetter (container, elmt, texts){
    let elmts = [];
    if(Array.isArray(texts))
        for(let i=0; i < texts.length; i++){
            elmts[i] = document.createElement(elmt);
            document.querySelector(container).appendChild(elmts[i]);
            writer(elmts[i], texts[i]);
        }

}

async function writer(elmt, text, index = 0){
    if(text.length > index){
        elmt.textContent += text[index];
        setTimeout(() => writer(elmt, text, ++index), 300);
    }
}